.App {
  text-align: center;
}
.background-row {
  background-image: 
    linear-gradient(to bottom, rgba(0, 0, 0, 0.52), rgba(0, 0, 0, 0.73)),
    url('assets/3.jpg');
  background-size: cover; 
  background-position: center; 
  height: 100vh;
  color: white; 
}
.background2-row {
  background-image: 
    linear-gradient(to bottom, rgba(0, 0, 0, 0.52), rgba(0, 0, 0, 0.73)),
    url('assets/6.jpg');
    background-position: center; 
    background-size: cover; 
    background-repeat: no-repeat; 
  color: white;
  height: 11rem;
  width: 100%;
}
.background3-row {
  background-image: 
    linear-gradient(to bottom, rgba(0, 0, 0, 0.52), rgba(0, 0, 0, 0.73)),
    url('assets/8.jpg');
    background-position: center; 
    background-size: cover; 
    background-repeat: no-repeat; 
  color: white;
  height: 11rem;
  width: 100%;
}
.background4-row {
  background-image: 
    linear-gradient(to bottom, rgba(0, 0, 0, 0.52), rgba(0, 0, 0, 0.73)),
    url('assets/5.jpg');
    background-position: center; 
    background-size: cover; 
    background-repeat: no-repeat; 
  color: white;
  height: 11rem;
  width: 100%;
}



.logo_name{
  padding:20%;
  flex-shrink: 0;

}

.custom-anchor .ant-anchor-link-title {
  font-size: 18px; 
  color: #ffff ;;  
}

.custom-anchor .ant-anchor-link-title:hover {
  color: hsl(209, 100%, 50%);
}
/* Hide the hamburger menu button by default (visible only on mobile) */
.menu-button {
  display: none;
}

/* Show the horizontal navigation by default */
.custom-anchor {
  display: inline-block;
}

/* Mobile-specific styles */
@media (max-width: 600px) {
  /* Hide the horizontal navigation on mobile */
  .custom-anchor {
    display: none;
  }
  
  /* Show the hamburger menu button on mobile */
  .menu-button {
    display: inline-block;
  }
}





